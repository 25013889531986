import styles from './Footer.module.css';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className={styles.footer}>
            <div className={styles.topLeft}>
                <p className={styles.mutedText}>Sheltering Scholars</p>
            </div>
            <div className={styles.linksRow}>
                <div className={styles.column}>
                    <h4 className={styles.title}>Connect</h4>
                    <a href="https://www.instagram.com/shelteringscholars" className={styles.link} target="_blank" rel="noopener noreferrer">
                        Instagram
                    </a>
                    <a href="tel:650-297-4981" className={styles.link}>
                        650-297-4981
                    </a>
                    <a href="mailto:mary@shelteringscholars.org" className={styles.link}>
                        mary@shelteringscholars.org
                    </a>
                </div>
                <div className={styles.column}>
                    <h4 className={styles.title}>Address</h4>
                    <a href="https://www.google.com/maps/search/?api=1&query=405+S+10th+Street+San+Jose+CA+95112" className={styles.link} target="_blank" rel="noopener noreferrer">
                        405 S 10th Street<br />
                        San Jose, CA 95112
                    </a>
                </div>
            </div>
            <div className={styles.copyright}>
                <p className={styles.footerText}>© {currentYear} Sheltering Scholars. All Rights Reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
