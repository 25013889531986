import styles from './Mission.module.css';
import mission from '../../images/mission.webp'; 

const Mission = () => {
    return (
        <section className={styles.missionSection} style={{ backgroundImage: `url(${mission})` }}>
            <div className={styles.contentContainer}>
                <div className={styles.leftSection}>
                    <h1 className={styles.heading}>
                        Supporting the affordable-housing movement through home-sharing.
                    </h1>
                </div>
                <div className={styles.rightSection}>
                    <h2 className={styles.subheading}>Mission Statement</h2>
                    <p className={styles.missionText}>
                        <i>Sheltering Scholars</i>’s Mission is to support college students in finding safe and affordable housing. <i>Sheltering Scholars</i> will build companionship between students and their hosts through home-sharing.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Mission;
