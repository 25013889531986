import brandynBlog from '../../blogImages/brandynBlog.webp';
import ankanaBlog from '../../blogImages/ankanaBlog.webp';
import takaraBlog from '../../blogImages/takaraBlog.webp';
import timothyBlog from '../../blogImages/timothyBlog.webp';
import oliviaBlog from '../../blogImages/oliviaBlog.webp';

const blogData = [
  {
    title: "Brandyn Evans: Overcoming Housing Insecurity",
    author: "Shristi Sigdel & Mary Sapigao",
    image: brandynBlog,
    content: `This May, I had the chance to speak with Brandyn Evans, a Maryland native and Computer Science student at Skyline College. He reached out after I invited students who had faced housing insecurity to share their experiences. We spoke in the Skyline Cafeteria, surrounded by the buzz of students grabbing lunch and typing term papers.\n\n
    Beneath this normal backdrop of college life, however, lies a harsher reality. Housing insecurity — which can include difficulties making housing payments, overcrowding, and frequent moving — affects 40-50% of college students. Brandyn’s experience is a striking example. On his 18th birthday, his family came to his house and told him he needed to leave. His mom said, “You’re a man now, you need to go.”\n\n
    “It was just crazy to me that my family would do that to me… I was a good kid. I never missed a single day of school. I stayed out of trouble… I think she was just done. She was a single parent. You know, it’s too much.”\n\n
    On Brandyn’s first night as an adult, he fell asleep on a tennis court.\n\n
    “The hardest thing was the first day. Not knowing what to do. And waking up on a tennis court and seeing people walking their dogs, like kind of ignoring me, like they pretended I wasn’t there… And that was like, ‘Whoa, this is what it feels like to be less than a human.’”\n\n
    After several weeks of sleeping in parks, he received a phone call from his neighbor. The neighbor, who worked at a homeless shelter, said that Brandyn’s mom reached out to him because she was worried for Brandyn. He told Brandyn that he had one bed available in his shelter if he wanted it.\n\n
    Thinking about that phone call, Brandyn remarks “I just started crying, just bawling. It was the most emotional part of my whole life.” This neighbor also helped him get a job through Job Corps.\n\n
    After some time, Brandyn set his sights on a new goal: working in the airline industry. He began working for Southwest Airlines but soon found himself exhausted from 12-hour shifts just to afford rent in Washington D.C. Recognizing that he needed a change for a better quality of life, Brandyn sold all of his belongings, bought a bike, and cycled his way on a cross-country journey to California.\n\n
    “It took me 40 days. 30 days from D.C. to Colorado, and 10 days to California.”\n\n
    After arriving in California, he bought a van and spent the next few years traveling and working for Uber Eats. Brandyn currently lives in his van while attending school full-time. He enjoys his nomadic lifestyle: “As weird as it sounds, I want to keep the van lifestyle forever.”\n\n
    Living in a van, however, has presented a unique set of challenges. He often works on campus to avoid the heat and cramped conditions of his van. He explains, “I want to do Zoom calls but I’m afraid someone might call the cops if I speak too loud. I can’t stay in there during the day because it’s hot.”\n\n
    Brandyn’s experiences have given him a deep understanding of the struggles his peers face. When attending SPARC meetings at Skyline College, he has witnessed firsthand how financial difficulties and lack of resources are pushing many students to drop out.\n\n
    Above all, Brandyn’s commitment to empowering others shines through. Whether he’s providing support to fellow students or adventurous cyclists, his desire to support and empower others continues to drive him forward.`
  },
  {
    title: "Ankana Ang: A Graduate’s Journey Through Housing Insecurity",
    author: "Shristi Sigdel & Mary Sapigao",
    image: ankanaBlog,
    content: `When thinking about the housing crisis in the US, most people immediately think of homelessness. It makes sense why — homelessness is a significant issue that is very obviously visible, especially in the Bay Area.\n\n
    But housing insecurity can also manifest in more subtle and insidious ways. Ankana, a graduate of SJSU, knows this reality all too well.\n\n
    Ankana’s parents live in Antioch. She was a commuter student at SJSU, but that 3-hour drive from Antioch started to make her days long and took away her ability to focus on studying and schoolwork. So, having to choose between going back home or studying, she started couch surfing in friends' houses.\n\n
    “I remember telling my mom, ‘I’m going to do whatever it takes to finish school. If I need to sleep in my car I’m going to do that.’ It’s not like my parents or I had the funds to pay for a space for me to stay.”\n\n
    Back home in Antioch, Ankana often faced another form of housing insecurity during her time at community college: overcrowding. Her parents are refugees from the Cambodian genocide, and they often open their home to extended family and others in need.\n\n
    Despite the safety and stability provided by her host family, the scars of her past experiences with housing insecurity still affect Ankana. Over the years, the stress of not knowing where she would sleep next took a toll on her negatively affecting her mental health. Ankana knows that she will transform her experiences into a source of strength and support for others.`
  },
  {
    title: "Takara McGee: Navigating Financial Strain & Housing Insecurity",
    author: "Shristi Sigdel",
    image: takaraBlog,
    content: `Imagine finding an eviction notice taped to the door of your dorm room. It’s a jarring image, since for most college freshmen, moving into a dorm symbolizes newfound independence and exciting new opportunities. But for Takara McGee, this note on her door was her reality. \n\n
    Takara is currently a student at College of San Mateo. But her college journey started at UC Riverside, where she was given a financial aid package that covered her tuition. However, the aid didn’t extend to housing or other fees, which quickly added up and turned out to be far more than she expected. \n\n
    A few weeks into her fall quarter, Takara’s grandmother passed away. She took time away from school to spend time with her family and take care of herself. In the winter quarter, she took a leave of absence from school because she was struggling with depression. Additionally,  she had an outstanding balance on her student account from housing costs that she wasn't able to afford. As a result, she wasn't able to sign up for classes in the following quarter. That’s when the eviction notice went up on her door, giving her 3 days to move out. \n\n
    Unable to return to UCR, she chose to attend College of San Mateo. At first, CSM tuition made her have to work 2 jobs, but in 2023, they waived all tuition and fees for students, which significantly eased her financial burdens.\n\n
    Looking back at her 18-year-old self, Takara wishes she had received more resources and guidance to handle her first year of college.\n\n
    “I needed more of that support from transitioning from being a kid to being an adult. Transitioning into living on your own and avoiding temptations that are all around you is hard.”\n\n
    Even more helpful would’ve been more financial support and greater transparency about the hidden fees of college. Many financial aid packages don’t take the cost of living into account when looking at family income. Takara found herself in a situation where her family’s income was too high for certain financial aid but too low to cover all expenses. \n\n
    She reached out to financial aid and student services for help, but loans and scholarships were her only options. Without a cosigner and lacking credit, she had poor loan options. “I’m young myself, I didn’t have credit, I was newly 18, so, of course, I didn’t have credit.”\n\n
    UC Riverside’s offer to her, looking back, felt exploitative: “It was like dangling in your face, ‘We’ll give you the education for free, you just have to find somewhere to live.’ Financial aid covered tuition, but just tuition … it’s ridiculous ...Room and board were the most expensive—around $3,200 a month. And the meal plan! You need a meal plan because you don’t have a kitchen”\n\n
    Now at the College of San Mateo, Takara is thriving. She lives with her mother and sister, allowing her to concentrate on her studies without financial strain. Takara is majoring in chemistry and working towards her dream of making positive changes in maternal health.\n\n
    “I want to go to medical school and be an OB-GYN. I want to work in developing countries where women experience high risks during and after childbirth and assist in prenatal and postnatal care. My end goal is to open my own practice.”\n\n
    Takara has shown amazing resilience, but her story shows that universities need to evolve. They must offer not only academic support but also clear financial assistance and transparency about all associated costs. Anything less fails to genuinely support students in the way that they need. By fixing issues with financial aid and cost transparency, colleges can better support student success.`
  },
  {
    title: "Timothy: Navigating COVID-19 and Housing Insecurity",
    author: "Shristi Sigdel",
    image: timothyBlog,
    content: `Meet Timothy, the youth director at Los Altos United Methodist Church.\n\n
    As it did for many others, the COVID-19 pandemic disrupted Timothy’s living situation and his sense of community. In early 2020, he was getting his Master of Divinity at Emory University. He lived with 3 of his classmates in a spiritual formation community, where they had scheduled activities and shared meals meant to nurture their faith and build community.\n\n
    When his classes became virtual and the world shut down, he had to make some quick decisions about where to live. His 3 housemates all lived local to Emory and chose to move back home with their parents. Not having that option, he realized he might have to live alone in the house. The prospect of isolation with no end in sight was not a good option for his mental well-being. So despite the stability and familiarity of the house, he moved out.\n\n
    “I wasn’t in a great head space. I know a lot of people at the time weren’t. And so I decided to make the call that I would rather go couch surf and be with people than rather have a roof over my head and be alone.”\n\n
    He stayed with his girlfriend, Olivia, and her parents -- now his wife and in-laws -- where he slept on their living room couch. And without having a dedicated personal space, Timothy struggled academically.\n\n
    “Sleeping in the living room, where it’s a shared common space, and having just a couple of suitcases made it much harder to focus on my education… It was the first and only time I dropped out of a class.”\n\n
    That being said, living with others was a good choice for him. Timothy’s need for community was more important than the difficulties that came with sharing space. Plus, he got along well with his in-laws, who were gracious and made the situation as comfortable as possible for him. It was, as he describes it, the “best-case scenario” given the circumstances, and he would do it all over again.\n\n
    He also had some financial support covering his rent from his previous employer, the Trinity Anglican Church in Georgia. They were distributing funds to those in need and told Timothy, ‘Whatever needs people have, we will cover that.’\n\n
    Timothy was lucky that he was able to couch surf with Olivia and her family without much trouble, and he knows that not everyone had those kinds of options when their world got upended by the pandemic. He advocates for the need to have more housing support for students who are struggling, and most importantly, for the support to be accessible. “I don’t want to be filling out tons and tons of paperwork when I’m wondering ‘Uhh, where am I going to crash tonight?’”`
  },
  {
    title: "Olivia Loewen-Elofson: Navigating Housing Insecurity and Compassion",
    author: "Shristi Sigdel",
    image: oliviaBlog,
    content: `Meet Olivia Loewen-Elofson, a graduate of Azusa Pacific University.\n\n
    For most of her life, her family served as the pastoral staff at their church. Her grandparents were the senior pastors, her parents were the associate pastors, and her sister was the children's pastor. In February of her senior year of high school, the denomination leaders told her entire family that they were removing them from their positions.\n\n
    Not only did this decision leave them without a job, it also left them without a home. Until then, Olivia and her parents lived in a parsonage, housing provided for church ministers. Without their church roles, they were given only a few months to move out of the parsonage.\n\n
    So, Olivia and her family had to uproot everything they knew and look for other permanent options. In the meantime, they house-sat for people they knew on vacation and couch-surfed with family members. But there were multiple days when her family didn't know where they would be sleeping the following night.\n\n
    “Starting with the very first night my mom had me looking up hotels… before she got the call from her brother that we could stay at their place that weekend.”\n\n
    As summer came to an end and it was time for Olivia to start college, her family still had not found a permanent place to stay. She opted to move into her dorm room two weeks early, which provided her with more stability and security.\n\n
    Financial constraints and the competitive LA market made it difficult for her parents to find permanent housing. Thankfully, her family ended up securing housing 8 weeks into Olivia’s freshman year.\n\n
    “Those first eight weeks were really rough … I was very reclusive for those 8 weeks because I didn't know how to function. I was very conscious of the fact that I would go home on the weekends to visit my family, but where I was visiting them was just a place they were house-sitting at like it wasn’t home.”\n\n
    She recalls how her housing insecurity seeped into her life in other ways.\n\n
    “I felt like I couldn’t possibly fit, and that added so many other beliefs about myself. That’s always kind of been there. Even in relationships and friendships, people don’t always realize how difficult that is. They can’t conceive of it.”\n\n
    Reflecting on this period of her life, Olivia feels disappointed in how the church left her family without support.\n\n
    "I know that house was empty after we moved out. I wish we had been allowed to live there until we found another place. Or they could have connected with someone to help us find a place to live. They could have helped us more by providing better access to affordable housing.”\n\n
    In an interesting turn of fate, Olivia and her parents later provided her now husband, Timothy, a place to stay when he was experiencing housing insecurity during the pandemic. Her family was more than willing to extend compassion, especially having gone through housing insecurity themselves. These shared experiences ultimately brought Olivia and Timothy closer together.`
  }  
];

export default blogData;
