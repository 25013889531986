import Volunteer from '../../components/Volunteer/Volunteer';
import Meet from '../../components/Meet/Meet';
import styles from './About.module.css';

const About = () => {
  const paragraphs = [
    "Sheltering Scholars is an innovative organization dedicated to bridging the gap between students and safe, affordable housing. Founded with a vision to support college students, we believe that a stable home environment is crucial to academic success and personal growth.",
    "Through our innovative home-sharing model, Sheltering Scholars connects students with compassionate hosts who are willing to open their homes. This pilot program not only alleviates housing stress but also fosters meaningful connections and community bonds. We are excited to explore how this model can positively impact both students and hosts as we gather feedback and refine our approach.",
    "Our mission extends beyond providing housing; it's about building a supportive network that empowers students to thrive. By partnering with local communities, we strive to ensure that every student has a safe place to call home during their academic journey."
  ];

  return (
    <>
    <div className={styles.aboutContainer}>
      <h1 className={styles.title}>About Us</h1>
      <p className={styles.missionStatement}>
        <strong>Mission Statement</strong><br />
        Sheltering Scholars’s Mission is to support college students in finding safe and affordable housing. Sheltering Scholars will build companionship between students and their hosts through home-sharing.
      </p>
      {paragraphs.map((paragraph, index) => (
        <p key={index} className={styles.paragraph}>
          {paragraph}
        </p>
      ))}
      <Volunteer />
    </div>
    <Meet />
    </>
  );
};

export default About;
