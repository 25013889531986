import reading from '../../images/reading.webp';
import styles from './Safety.module.css';

const Safety = () => {
  return (
    <div className={styles.safetyContainer}>
      <div className={styles.textSection}>
        <h2 className={styles.title}>How we ensure safety and compatibility with our students and hosts:</h2>
        <ol className={styles.list}>
          <li className={styles.listItem}>A volunteer of Sheltering Scholars accompanies the student to meet the host at their home.</li>
          <li className={styles.listItem}>An unbiased third-party mediator oversees a roommate agreement between the student and host.</li>
          <li className={styles.listItem}>Background checks are performed on both the student and host.</li>
        </ol>
      </div>
      <div className={styles.imageSection}>
        <img className={styles.image} src={reading} alt="Safety and compatibility" />
      </div>
    </div>
  );
};

export default Safety;
