import students from '../../images/students.webp';
import styles from './HomeBanner.module.css';

const HomeBanner = () => {
  return (
    <div className={styles.homeBannerContainer}>
      <img src={students} alt="Happy Students" className={styles.homeBannerImage} />
      <div className={styles.homeBannerTextOverlay}>
        <h1 className={styles.homeBannerTitle}>Sheltering Scholars</h1>
        <p className={styles.homeBannerSubtitle}>Investing in Students' Futures and Building Companionship.</p>
      </div>
    </div>
  );
};

export default HomeBanner;
