import { Routes, Route, useLocation } from "react-router-dom";
import styles from './Contact.module.css';
import contact from '../../images/contact.webp';
import contact2 from '../../images/contact2.webp';
import contact3 from '../../images/contact3.webp';
import ContactForm from "../../components/ContactForm/ContactForm";

const formDescriptions = {
  host: "The Host Interest Form is designed for individuals who are interested in offering their home or space to college students in need of housing. Please provide details about the space and your availability.",
  housingInsecure: "Are you a current or former student who has faced housing insecurity? We want to hear your story to help spread awareness of this growing issue. This form is for students willing to share their experiences in a potential interview to help de-stigmatize housing insecurity. We are here to listen.",
  general: "The General Inquiry Form is for anyone with questions, comments, or requests for information. Whether you need help or want to get involved, this is the place to start."
};


const Contact = () => {
  const location = useLocation();
  
  const getBackgroundImage = () => {
    switch (location.pathname) {
      case '/contact/host':
        return contact;
      case '/contact/housing-insecure':
        return contact2;
      case '/contact/general':
        return contact3;
      default:
        return contact; 
    }
  };

  return (
    <div className={styles.contactPage} style={{ backgroundImage: `url(${getBackgroundImage()})` }}>
      <div className={styles.formDescription}>
        <Routes>
          <Route path="host" element={<p>{formDescriptions.host}</p>} />
          <Route path="housing-insecure" element={<p>{formDescriptions.housingInsecure}</p>} />
          <Route path="general" element={<p>{formDescriptions.general}</p>} />
          <Route path="*" element={<p>Please select a form to get started.</p>} />
        </Routes>
      </div>
      <div className={styles.formContainer}>
        <Routes>
          <Route path="host" element={<ContactForm formType="host" />} />
          <Route path="housing-insecure" element={<ContactForm formType="housingInsecureStudents" />} />
          <Route path="general" element={<ContactForm formType="generalInquiry" />} />
        </Routes>
      </div>
    </div>
  );
};

export default Contact;
