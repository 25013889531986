import ReactPlayer from "react-player";
import styles from "./VideoPlayer.module.css";

const VideoPlayer = () => {
  return (
    <div className={styles.videoPlayerContainer}>
      <div className={styles.videoWrapper}>
        <ReactPlayer
          url="https://www.youtube.com/watch?v=Yb2bVAg45a8" 
          controls={true}
          width="100%"
          height="100%"
        />
      </div>
      <div className={styles.videoSpacing}></div>
      <div className={styles.videoWrapper}>
        <ReactPlayer
          url="https://www.youtube.com/watch?v=R4SzJGmt0hA" 
          controls={true}
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
};

export default VideoPlayer;
