import { useState } from 'react';
import styles from './NewsletterSignup.module.css';

const NewsletterSignup = () => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('Sending...');
    
    try {
      const response = await fetch('https://api.brevo.com/v3/contacts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_SENDINBLUE, 
        },
        body: JSON.stringify({
          email: email,
          listIds: [2], 
          updateEnabled: true,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        setStatus('Subscription successful!');
      } else {
        setStatus(`Subscription failed: ${data.message}`);
      }
    } catch (err) {
      setStatus('An error occurred. Please try again.');
    }

    setTimeout(() => {
      setEmail(''); 
      setStatus(''); 
    }, 4000);
  };

  return (
    <div className={styles.newsletterContainer}>
      <h2 className={styles.title}>Subscribe to our newsletter!</h2>
      <p className={styles.description}>
        Sign up with your email address to follow our journey.
      </p>
      <form onSubmit={handleSubmit} className={styles.form}>
        <input
          type="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className={styles.input}
        />
        <button type="submit" className={styles.button}>
          Sign up
        </button>
      </form>
      {status && <p className={styles.status}>{status}</p>}
    </div>
  );
};

export default NewsletterSignup;
