import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function useTitle() {
  const location = useLocation();

  useEffect(() => {
    const baseTitle = "Sheltering Scholars";
    let title = baseTitle;

    switch (location.pathname) {
      case "/":
        title = baseTitle + " | HOME";
        break;
      case "/about":
        title = baseTitle + " | ABOUT";
        break;
      case "/blog":
        title = baseTitle + " | BLOG";
        break;
      case "/contact":
        title = baseTitle + " | CONTACT";
        break;
      case "/need-help":
        title = baseTitle + " | NEED HELP";
        break;
      default:
        title = baseTitle;
        break;
    }

    document.title = title;
  }, [location]);
}

export default useTitle;
