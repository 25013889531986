import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import styles from './Volunteer.module.css';

const Volunteer = () => {
  const location = useLocation();
  
  let email = 'mailto:shelteringscholars@gmail.com';
  let subject = 'Become a Volunteer at Sheltering Scholars';
  let text = 'Want to help us? Click the icon below to send an email and learn more about becoming a volunteer!';

  if (location.pathname === '/blog') {
    subject = 'Submit Your Story to Sheltering Scholars';
    text = 'Have a story to share? Click the icon below to submit your story and get featured on our blog!';
  }

  return (
    <div className={styles.volunteerContainer}>
      <p className={styles.text}>{text}</p>
      <a 
        href={`${email}?subject=${encodeURIComponent(subject)}`} 
        className={styles.iconLink}
      >
        <FontAwesomeIcon icon={faEnvelope} className={styles.icon} />
      </a>
    </div>
  );
};

export default Volunteer;
