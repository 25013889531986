import { useState } from 'react';
import Masonry from 'react-masonry-css';
import { motion } from 'framer-motion';
import styles from './Blog.module.css';
import blogData from './blogData';
import Volunteer from '../../components/Volunteer/Volunteer';
import NewsletterSignup from '../../components/NewsletterSignup/NewsletterSignup';

const Blog = () => {
  const [selectedBlog, setSelectedBlog] = useState(null);

  const openModal = (blog) => {
    setSelectedBlog(blog);
  };

  const closeModal = () => {
    setSelectedBlog(null);
  };

  const formatContent = (content) => {
    return content.split('\n\n').map((paragraph, index) => (
      <p key={index} className={styles.modalText}>
        {paragraph}
      </p>
    ));
  };

  return (
    <div className={styles.blogContainer}>
      <p className={styles.blogIntro}>
        At Sheltering Scholars, we believe that every story has the power to inspire and connect us. 
        Our blog highlights the personal experiences of students and hosts who have built lasting relationships through our home-sharing model. 
        These real stories go beyond housing—they’re about the friendships, support, and personal growth that emerge when people open their hearts and homes to one another. 
        Join us as we share the journeys of those who have found not only a place to stay, but a community to belong to.
      </p>
      <Volunteer />
      <Masonry
        breakpointCols={{ default: 3, 1100: 2, 700: 1 }}
        className={styles.masonryGrid}
        columnClassName={styles.masonryGridColumn}
      >
        {blogData.slice().reverse().map((blog, index) => (
          <div key={index} className={styles.blogItem} onClick={() => openModal(blog)}>
            <img src={blog.image} alt={blog.title} className={styles.blogImage} />
            <h2 className={styles.blogTitle}>{blog.title}</h2>
          </div>
        ))}
      </Masonry>
      <NewsletterSignup />

      {selectedBlog && (
        <motion.div
          className={styles.modal}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div className={styles.modalContent} initial={{ scale: 0.8 }} animate={{ scale: 1 }}>
            <button className={styles.closeButton} onClick={closeModal}>Close</button>
            <img src={selectedBlog.image} alt={selectedBlog.title} className={styles.modalImage} />
            <h2 className={styles.modalTitle}>{selectedBlog.title}</h2>
            {formatContent(selectedBlog.content)}
            <p className={styles.modalAuthor}>Written by: {selectedBlog.author}</p>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

export default Blog;
