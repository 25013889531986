import styles from './Help.module.css';

const Help = () => {
  const openHelpPage = () => {
    window.open('/need-help', '_blank');
  };

  return (
    <div className={styles.helpContainer}>
      <button className={styles.helpButton} onClick={openHelpPage}>
        Need Help?
      </button>
    </div>
  );
};

export default Help;
