import HomeBanner from "../../components/HomeBanner/HomeBanner";
import Mission from "../../components/Mission/Mission";
import Safety from "../../components/Safety/Safety";
import Volunteer from "../../components/Volunteer/Volunteer";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import NewsletterSignup from "../../components/NewsletterSignup/NewsletterSignup";

const Home = () => {
    return (
        <div>
            <HomeBanner />
            <Mission />
            <Safety />
            <VideoPlayer />
            <Volunteer />
            <NewsletterSignup />
        </div>
    );
    };

export default Home;