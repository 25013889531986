import { useState } from 'react';
import emailjs from '@emailjs/browser';
import styles from './ContactForm.module.css';
import useContactFormValidation from '../../hooks/useContactFormValidation';
import formDataConfig from './formData';

const ContactForm = ({ formType }) => {
    const { title, fields } = formDataConfig[formType];
    const initialFormState = fields.reduce((acc, field) => {
        if (field.type === 'checkbox') {
            acc[field.id] = [];  // Initialize checkbox fields as an empty array
        } else if (field.type === 'radio') {
            acc[field.id] = '';  // Initialize radio fields as empty string
        } else {
            acc[field.id] = '';  // Initialize other fields as empty string
        }
        return acc;
    }, {});

    const [formState, setFormState] = useState(initialFormState);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");

    const { errors, isFormValid } = useContactFormValidation(formState, formType);

    // Map formType to the corresponding form title
    const formTitles = {
        host: 'Sheltering Scholars Host Interest Form',
        housingInsecureStudents: 'Housing Insecure Students Form',
        generalInquiry: 'General Contact Inquiry Form'
    };

    const handleChange = (e) => {
        const { name, type, value, checked } = e.target;

        if (type === 'checkbox') {
            setFormState(prevState => {
                const newArray = checked
                    ? [...prevState[name], value]  // Add to array if checked
                    : prevState[name].filter(v => v !== value);  // Remove from array if unchecked
                return { ...prevState, [name]: newArray };
            });
        } else {
            setFormState({ ...formState, [name]: value });  // Handle other input types
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!isFormValid) return; // Ensure form is valid before submitting

        setIsSubmitting(true);

        const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
        const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_COMBINED; // Use the combined template
        const userId = process.env.REACT_APP_EMAILJS_USER_ID;

        // Add formTitle to formState
        const formStateWithFormTitle = {
            ...formState,
            formTitle: formTitles[formType],  // Include the correct form title based on formType
        };

        // Filter out empty fields
        const filteredFormState = Object.fromEntries(
            Object.entries(formStateWithFormTitle).filter(([key, value]) => value !== '' && value.length !== 0)
        );

        emailjs.send(serviceId, templateId, filteredFormState, userId)
            .then((response) => {
                setResponseMessage("Message sent successfully!");
                setIsSubmitting(false);
                setTimeout(() => {
                    setResponseMessage("");
                    setFormState(initialFormState);
                }, 5000);
            }, (error) => {
                setResponseMessage("Failed to send message. Please try again later.");
                setIsSubmitting(false);
                setTimeout(() => {
                    setResponseMessage("");
                }, 5000);
            });
    };

    return (
        <form onSubmit={handleSubmit} className={styles.contactForm}>
            <h1 className={styles.contactFormTitle}>{title}</h1>
            {fields.map(field => (
                <div key={field.id} className={styles.formGroup}>
                    <div className={styles.labelWrapper}>
                        <label htmlFor={field.id} className={styles.label}>
                            {field.label}
                            {errors[field.id] && <span className={styles.error}> {errors[field.id]}</span>}
                        </label>
                    </div>
                    {field.type === "textarea" ? (
                        <textarea
                            id={field.id}
                            name={field.id}
                            value={formState[field.id]}
                            onChange={handleChange}
                            className={styles.inputField}
                            style={{ height: '5rem' }}
                        />
                    ) : field.type === "radio" ? (
                        <div className={styles.radioGroup}>
                            {field.options.map(option => (
                                <label key={option} className={styles.radioLabel}>
                                    <input
                                        type="radio"
                                        name={field.id}
                                        value={option}
                                        checked={formState[field.id] === option}
                                        onChange={handleChange}
                                    />
                                    {option}
                                </label>
                            ))}
                        </div>
                    ) : field.type === "checkbox" ? (
                        <div className={styles.checkboxGroup}>
                            {field.options.map(option => (
                                <label key={option} className={styles.checkboxLabel}>
                                    <input
                                        type="checkbox"
                                        name={field.id}
                                        value={option}
                                        checked={formState[field.id].includes(option)}
                                        onChange={handleChange}
                                    />
                                    {option}
                                </label>
                            ))}
                        </div>
                    ) : (
                        <input
                            type={field.type}
                            id={field.id}
                            name={field.id}
                            value={formState[field.id]}
                            onChange={handleChange}
                            className={styles.inputField}
                        />
                    )}
                </div>
            ))}
            {responseMessage && <p className={styles.responseMessage}>{responseMessage}</p>}
            <button type="submit" disabled={isSubmitting || !isFormValid} className={styles.submitButton}>
                Send
            </button>
        </form>
    );
};

export default ContactForm;
