import styles from './Meet.module.css';
import maryImage from '../../images/maryImage.webp';
import ankanaImage from '../../images/ankanaImage.webp';
import pastorSamImage from '../../images/pastorSamImage.webp';

const teamData = [
  {
    name: "Mary Sapigao",
    image: maryImage,
    bio: [
      "My name is Mary, and I am the Founder of Sheltering Scholars. After experiencing housing insecurity in San Diego while studying pre-nursing, I was inspired to support other college students who were also housing insecure.",
      "I started this project because many people have not truly learned what housing insecurity is and believe that it is only if you are sleeping on the streets or in a homeless shelter. However, housing insecurity is so much more than just that. It is sleeping in your car, relying on friends to couch surf, and rooming with several other students to afford rent.",
      "Other than supporting the affordability housing movement, I am a big advocate for mental health. I studied neuroscience at UC San Diego, emphasizing my studies on how the physiology of the brain can affect our mental health. I ultimately dream of becoming a psychiatric nurse practitioner to support youth and young adults.",
      "For fun, I really like to play music, hike with my friends, help out at my sister’s farm, and have bonfires on the beach!"
    ]
  },
  {
    name: "Ankana Ang",
    image: ankanaImage,
    bio: [
        "My name is Ankana, and I am the Project Coordinator and Social Media Manager for Sheltering Scholars. Having lived in various parts of the Bay Area—Oakland, El Sobrante, San Pablo, Antioch, Milpitas, and San Jose—I graduated from San Jose State University in Fall 2023. Recently, I relocated to New York to further explore my individuality and creativity while in hopes of pursuing a PhD in Clinical Psychology at Columbia University. I enjoy dancing, roller skating, traveling, discovering new restaurants, hiking, painting, singing, reading, experimenting with makeup, and constantly picking up new hobbies.",
        "As a child of genocide survivors who faced housing insecurity during my academic journey, I was motivated to join Mary’s project to raise awareness about housing challenges and seek safe solutions for students who share similar experiences.",
    ]
  },
  {
    name: "Rev. Sam Blewis",
    image: pastorSamImage,
    bio: [
      "Rev. Sam Blewis is the pastor of St. Paul's United Methodist Church in San Jose, where she leads a congregation deeply committed to addressing housing insecurity and fostering a welcoming, affirming space for all people. An ordained minister in the United Methodist Church, Sam is passionate about issues of justice and inclusion. St. Paul's is actively working to develop affordable housing on its church campus."
    ]
  }
];

const Meet = () => {
  return (
    <div className={styles.meetContainer}>
    <h1 className={styles.title}>Meet Us</h1>
      {teamData.map((member, index) => (
        <div key={index} className={styles.memberContainer}>
          <div className={styles.textArea}>
            <h2 className={styles.name}>{member.name}</h2>
            {member.bio.map((paragraph, i) => (
              <p key={i} className={styles.bio}>{paragraph}</p>
            ))}
          </div>
          <div className={styles.imageArea}>
            <img src={member.image} alt={member.name} className={styles.image} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Meet;
