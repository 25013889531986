import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useState } from 'react';
import styles from './Header.module.css';

const navLinks = [
  { name: 'Home', path: '/' },
  { name: 'About', path: '/about' },
  { name: 'Blog', path: '/blog' },
];

const contactLinks = [
  { name: 'Host Interest Form', path: '/contact/host' },
  { name: 'Housing Insecure Students', path: '/contact/housing-insecure' },
  { name: 'General Inquiry', path: '/contact/general' },
];

const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(prev => !prev);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  return (
    <header className={styles.header}>
      <nav className={styles.nav}>
        {navLinks.map((link, index) => (
          <Link key={index} to={link.path} className={styles.navLink}>
            {link.name}
          </Link>
        ))}
        <div className={styles.dropdownContainer}>
          <span className={styles.navLink} onClick={toggleDropdown}>
            Contact
          </span>
          {isDropdownOpen && (
            <motion.div 
              className={styles.dropdownMenu}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              transition={{ duration: 0.3 }}
            >
              <div className={styles.modalOverlay} onClick={closeDropdown} />
              <motion.div 
                className={styles.modalContent}
                initial={{ y: -50 }}
                animate={{ y: 0 }}
                exit={{ y: -50 }}
                transition={{ duration: 0.3 }}
              >
                <h2 className={styles.modalTitle}>Select a Contact Form</h2>
                {contactLinks.map((contactLink, index) => (
                  <Link 
                    key={index} 
                    to={contactLink.path} 
                    className={styles.dropdownLink}
                    onClick={closeDropdown}
                  >
                    {contactLink.name}
                  </Link>
                ))}
                <button className={styles.closeButton} onClick={closeDropdown}>Close</button>
              </motion.div>
            </motion.div>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Header;
