import { useState, useEffect, useCallback } from 'react';

const useContactFormValidation = (formData, formType) => {
    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);

    // useCallback to memoize the validateForm function
    const validateForm = useCallback((formData) => {
        const newErrors = {};

        // General validation (applies to all forms)
        if (!formData.name?.trim()) {
            newErrors.name = "*";
        }

        if (!formData.email?.trim()) {
            newErrors.email = "*";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
            newErrors.email = "Invalid email address";
        }

        // Form-specific validations
        if (formType === "host") {
            if (!formData.cityZip?.trim()) {
                newErrors.cityZip = "*";
            }
            if (!formData.liabilityInsurance) {
                newErrors.liabilityInsurance = "Please select an option.";
            }
            if (!formData.spaceType?.trim()) {
                newErrors.spaceType = "*";
            }
            if (!formData.hostingReason?.trim()) {
                newErrors.hostingReason = "*";
            }
            if (!formData.risksHosting?.trim()) {
                newErrors.risksHosting = "*";
            }
            if (!formData.dealBreakers?.trim()) {
                newErrors.dealBreakers = "*";
            }
            if (!formData.idealRoommate?.trim()) {
                newErrors.idealRoommate = "*";
            }
        } else if (formType === "housingInsecureStudents") {
            if (!formData.school?.trim()) {
                newErrors.school = "*";
            }
            if (!formData.availabilityDays?.length) {
                newErrors.availabilityDays = "Please select at least one day.";
            }
            if (!formData.availabilityTime) {
                newErrors.availabilityTime = "Please select a time.";
            }
            if (!formData.interviewType) {
                newErrors.interviewType = "Please select an interview type.";
            }
        } else if (formType === "generalInquiry") {
            if (!formData.subject?.trim()) {
                newErrors.subject = "*";
            }
            if (!formData.message?.trim()) {
                newErrors.message = "*";
            }
        }

        return newErrors;
    }, [formType]);

    useEffect(() => {
        const newErrors = validateForm(formData);
        setErrors(newErrors);
        setIsFormValid(Object.keys(newErrors).length === 0);
    }, [formData, validateForm]);

    return { errors, isFormValid };
};

export default useContactFormValidation;
