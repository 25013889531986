const formData = {
    host: {
        title: "Sheltering Scholars Host Interest Form",
        fields: [
            { id: "name", label: "Name", type: "text" },
            { id: "phoneNumber", label: "Phone Number", type: "tel" },
            { id: "email", label: "Email Address", type: "email" },
            { id: "cityZip", label: "City and Zip Code", type: "text" },
            { id: "liabilityInsurance", label: "Do you have homeowners liability insurance?", type: "radio", options: ["Yes", "No"] },
            { id: "spaceType", label: "What type of space are you interested in offering?", type: "radio", options: ["Single Bedroom", "Accessory Dwelling Unit (ADU)", "Other"] },
            { id: "hostingReason", label: "Why are you interested in hosting a college student, and what benefits do you foresee?", type: "textarea" },
            { id: "risksHosting", label: "What concerns or potential risks do you have about hosting a student?", type: "textarea" },
            { id: "dealBreakers", label: "Are there any specific deal breakers when it comes to sharing your space? If so, what are they?", type: "textarea" },
            { id: "idealRoommate", label: "What qualities do you seek in an ideal student roommate?", type: "textarea" },
        ]
    },
    housingInsecureStudents: {
        title: "Interview Questions for Housing Insecure Students",
        description: "Are you a current or former student who has faced housing insecurity? Do you want to share your story and help de-stigmatize this fast-growing issue? Please fill out this form so we can connect! We are here to listen.",
        fields: [
            { id: "name", label: "Name", type: "text" },
            { id: "school", label: "School Attending (Or Attended)", type: "text" },
            { id: "email", label: "Email Address", type: "email" },
            { id: "phoneNumber", label: "Phone Number", type: "tel" },
            { id: "availabilityDays", label: "What days are you typically available to meet?", type: "checkbox", options: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"] },
            { id: "availabilityTime", label: "What time are you generally available to talk?", type: "radio", options: ["Morning (9 am to 11:59 am)", "Early Afternoon (12pm to 3pm)", "Late Afternoon (3pm to 6pm)", "Evening (6 pm to 8 pm)"] },
            { id: "interviewType", label: "Are you available for an in-person or zoom interview?", type: "radio", options: ["In-person interview", "Zoom", "Both"] }
        ]
    },
    generalInquiry: {
        title: "General Contact Inquiry Form",
        fields: [
            { id: "name", label: "Full Name", type: "text" },
            { id: "phoneNumber", label: "Phone Number", type: "tel" },
            { id: "email", label: "Email Address", type: "email" },
            { id: "subject", label: "Subject of Inquiry", type: "text" },
            { id: "message", label: "Please describe your inquiry or the information you are seeking.", type: "textarea" },
            { id: "preferredContactMethod", label: "How would you prefer we contact you?", type: "radio", options: ["Email", "Phone", "No Preference"] },
            { id: "bestTime", label: "If applicable, what is the best time to reach you?", type: "text" }
        ]
    }
};

export default formData;
