import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Help from "./components/Help/Help";
import Resources from "./pages/Resources/Resources";
import Header from "./components/Header/Header";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Blog from "./pages/Blog/Blog";
import Footer from "./components/Footer/Footer";
import useTitle from "./hooks/useTitle";
import "normalize.css";
import './App.css';

const pageTransition = {
  in: {
    opacity: 1,
    transition: {
      duration: 0.5
    }
  },
  out: {
    opacity: 0,
    transition: {
      duration: 0.5
    }
  }
};

const routes = [
  { path: "/", Component: Home },
  { path: "/contact/*", Component: Contact },
  { path: "/about", Component: About },
  { path: "/blog", Component: Blog },
  { path: "/need-help", Component: Resources }
];

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  useTitle();
  const location = useLocation(); 

  return (
    <>
      <Help />
      <Header />
      <AnimatePresence mode="wait"> 
        <Routes location={location} key={location.pathname}>
          {routes.map(({ path, Component }) => (
            <Route
              key={path}
              path={path}
              element={
                <motion.div initial="out" animate="in" exit="out" variants={pageTransition}>
                  <Component />
                </motion.div>
              }
            />
          ))}
        </Routes>
      </AnimatePresence>
      <Footer />
    </>
  );
}

export default App;
