import styles from './Resources.module.css';
import resources from '../../images/resources.webp';

const resourcesData = [
  {
    name: "Bill Wilson Center",
    website: "https://www.billwilsoncenter.org/",
    phone: "(408) 243-0222",
    email: "YRodriguez@billwilsoncenter.org"
  },
  {
    name: "Covenant House",
    website: "https://www.covenanthouse.org/homeless-shelters",
    phone: "408-703-9503"
  }
];

const Resources = () => {
  return (
    <div className={styles.resourcesContainer} style={{ backgroundImage: `url(${resources})` }}>
      <h1 className={styles.title}>Available Housing Resources</h1>
      <p className={styles.description}>
        These resources are available to help young adults in Santa Clara County who are in need of housing support. 
        Please reach out to them directly for assistance.
      </p>
      <div className={styles.resourcesList}>
        {resourcesData.map((resource, index) => (
          <div key={index} className={styles.resource}>
            <h2 className={styles.resourceName}>{resource.name}</h2>
            <p className={styles.resourceInfo}>
              Website: <a href={resource.website} target="_blank" rel="noopener noreferrer" className={styles.link}>{resource.website}</a>
            </p>
            <p className={styles.resourceInfo}>
              Phone Number: <a href={`tel:${resource.phone}`} className={styles.link}>{resource.phone}</a>
            </p>
            {resource.email && (
              <p className={styles.resourceInfo}>
                Email Address: <a href={`mailto:${resource.email}`} className={styles.link}>{resource.email}</a>
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Resources;
